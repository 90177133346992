import { Container } from "typescript-ioc";
import { ReadyHelper } from "Events/Scripts/ReadyHelper";
import { ConsultantRegistration } from "./ConsultantRegistration";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { Utils } from "Helpers/Scripts/Utils";

declare global {
    interface IOri {
        consultants: {
            registration: ConsultantRegistration;
        };
    }
}

// instantiate
const consultantRegistration = Container.get(ConsultantRegistration);
// publish for access from legacy JS code
ori.consultants.registration = consultantRegistration;
// init from markup data on DOM ready
Container.get(ReadyHelper).bindInitCodeBlocksReady(() => {
    const selector: string = ".js-form-container";
    if (Utils.find("html", selector, 1).length > 0) {
        Container.get(UiComponentFactory).createBase(ConsultantRegistration, selector);
    }
});
