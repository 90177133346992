import { Inject } from "typescript-ioc";
import { HandlePartialViewAnalytics } from "@ori-events/handle-partial-view-analytics";
import { FetchService } from "Async/Scripts/FetchService";
import { autobind } from "core-decorators";
import { DocumentComponentFactory } from "Documents/Scripts/DocumentComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { SubmitButtonManager } from "Helpers/Scripts/SubmitButtonManager";
import { Utils } from "Helpers/Scripts/Utils";
import { ILogger } from "Logging/Scripts/ILogger";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { Form } from "Ui/Scripts/Form";
import { FormSubmitMode } from "Ui/Scripts/FormSubmitMode";
import { ISubmitResponse } from "Ui/Scripts/ISubmitResponse";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import "../Styles/registration-form.css";
import { Div } from "Ui/Scripts/Div";
import { Span } from "Ui/Scripts/Span";
import { SponsorChangeInviteContent } from "./SponsorChangeInviteContent";

interface IFormData {
    ElementName: string;
    ElementValue: string | Blob;
    Errors: kendo.ui.IValidationErrors;
}

export class ConsultantRegistration extends UiComponent {
    public get key(): string {
        return "ConsultantRegistration";
    }

    private _form: Form;
    private _validator: kendo.ui.Validator;
    private _summarizationWindow?: kendo.ui.Window;
    private readonly _fetchService: FetchService;
    protected readonly _logger: ILogger;
    private _ajaxErrorTitle: string;
    private _ajaxErrorMessage: string;
    private _isEnabledSummarization: boolean = false;
    private _isSummarizationOpened: boolean = false;
    private _logFormElementsStateUrl: string;
    private _showSummarizationPopupUrl: string;
    private _uniqueId?: kendo.ui.TextBox;
    private _isForeignCheckBox?: kendo.ui.CheckBox;
    private _submitBtn: kendo.ui.Button;
    private _searchFormConsultantNumber?: kendo.ui.TextBox;
    private _foreignRegistrationSerchForm?: Form;
    private _sectionConsultantNumber?: HTMLDivElement;
    private _emailInput?: HTMLInputElement;
    private _telephoneInput?: HTMLInputElement;
    private _sponsorNumber?: kendo.ui.TextBox;
    private _sponsorFinalDetailsHidden?: HTMLInputElement;
    private _sponsorName?: HTMLInputElement;
    private _isSelfRegistration: boolean;
    private _sponsorRadioButtons: kendo.ui.RadioButton[] = [];
    private _documentComponentFactory: DocumentComponentFactory;
    private _disableSubmitTimer: number;
    private _submitButtonManager: SubmitButtonManager;
    private _sponsorIsMeEnumValue: string;
    private _visibilityToggleCheckbox?: kendo.ui.CheckBox;
    private _termsAndConditionsElement?: HTMLLabelElement;
    private _sponsorChangeInviteWindow?: kendo.ui.Window;

    private _approvalToggle?: Div;
    private _approvalToggleIcon?: Span;
    private _approvalSectionBlock?: Div;

    private readonly DATA_CONSULTANT_TYPE_HIDE_FOR: string = "hide-for-consultant-type";
    private readonly TELEPHONE_INPUT: string = ".js-telephone input";
    private readonly EMAIL_INPUT: string = ".js-email input";
    private readonly FOREIGN_REGISTRATION_SEARCH_FORM: string =
        ".js-foreign-registration-search-form";
    private readonly CONSULTANT_REGISTRATION_FORM: string = ".js-consultant-registration";
    private readonly SELF_REGISTRATION_FORM: string = ".js-self-registration-form";
    private readonly BIRTHDATE_INPUT: string = ".js-birthdate input";
    private readonly BIRTHDATE_DEFAULT_YEAR: string = "data-default-year";
    private readonly UNIQUE_ID_RELATED_FIELD_SEL: string = "input.js-unique-id-related-field";
    private readonly UNIQUE_ID_SEL: string = "input.js-reg-unique-id";
    private readonly TERMS_AND_CONDITIONS_SEL: string = ".js-terms-and-conditions";
    private readonly TERMS_AND_CONDITIONS_SUMMARY_SEL: string =
        "#js-terms-and-conditions-summarization";
    private readonly UNIQUE_ID_TRIGGER_VALIDATION_BY_RELATED_FIELDS_DATA: string =
        "trigger-validation-by-related-fields";
    private readonly AJAX_ERROR_TITLE: string = "ajax-error-title";
    private readonly AJAX_ERROR_MESSAGE: string = "ajax-error-message";
    private readonly LOG_FORM_ELEMENTS_STATE: string = "log-form-elements-state";
    private readonly SHOW_SUMMARIZATION_POPUP: string = "show-summarization-popup";
    private readonly IS_ENABLED_SUMMARIZATION: string = "is-enabled-summarization";
    private readonly DATA_FOREIGN_UNIQUE_ID_ATTR: string = "data-foreign-unique-id";
    private readonly BUTTON_SUBMIT: string = "button[type=submit]";
    private readonly SEL_SEARCH_FORM_CONSULTANT_NUMBER: string =
        "#foreignRegistrationConsultantNumber";
    private readonly REGISTRATION_SUCCESS_DIALOG: string = "#registration-complete-window";
    private readonly SUMMARIZATION_POPUP: string = "#summarization";
    private readonly SEL_SUMMARIZATION_CONFIRM: string = ".js-summarization-confirm";
    private readonly SEL_SUMMARIZATION_CLOSE: string = ".js-summarization-close";
    private readonly SPONSOR_NAME: string = "#sponsor-name";
    private readonly SPONSOR_NUMBER: string = "#SponsorNumber";
    private readonly SPONSOR_FINAL_DETAILS_HIDDEN_ID: string = "#SponsorFinalDetailsHidden";
    private readonly SECTION_CONSULTANT_NUMBER: string = ".js-section-consultant-number";
    private readonly DATA_COLLAPSE: string = "data-collapse";
    private readonly CONSULTANT_NUMBER: string = "consultantNumber";
    private readonly SEL_SPONSOR_RADIO_INPUT: string = ".js-sponsor-radio .k-radio.k-input";
    private readonly UNIQUE_ID_INPUT_NAME: string = "UniqueId";
    private readonly DISABLE_SUBMIT_FOR: string = "unique-id-related";
    private readonly DISABLE_SUBMIT_TIMEOUT: number = 15000;
    private readonly SPONSOR_RADIO_GROUP_ID: string = "#js-sponsor-radio-group";
    private readonly SPONSOR_CONSTANT_KEY: string = "constant-sponsor-is-me";
    private summarizationSubmit: boolean = true;
    private readonly SUMMARIZATION_EVENT_NAMESPACE: string = "registrationsummarization";
    private readonly VISIBILITY_TOGGLE: string = ".js-toggle-visibility";
    private readonly VISIBILITY_TOGGLE_TARGET: string = "toggle-visibility-target";
    private readonly INVERT_VISIBILITY_TOGGLE: string = "invert-toggle-visibility";
    private readonly FOREIGN_CHECKBOX_SEL = ".js-foreign-checkbox";

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory,
        @Inject fetchService: FetchService,
        @Inject documentComponentFactory: DocumentComponentFactory
    ) {
        super(componentFactory, binder, loggerFactory);
        this._logger = loggerFactory.getLogger(this.key);
        this._fetchService = fetchService;
        this._documentComponentFactory = documentComponentFactory;
    }

    /**
     * Initialization
     */
    public init(initForeignSearchForm: boolean = true): void {
        this._findElements();
        this._initForm();
        if (initForeignSearchForm) {
            this._initForeignRegistrationSearchForm();
        }
        this._setOptions();
        this._bindEvents();
        if (this._sponsorChangeInviteWindow) {
            this._sponsorChangeInviteWindow.center().open();
        }
    }

    /**
     * Store elements
     */
    private _findElements(): void {
        this._uniqueId = kendo.getTextBox(this.UNIQUE_ID_SEL);

        this._isForeignCheckBox = kendo.getCheckBox(this.FOREIGN_CHECKBOX_SEL);
        this._summarizationWindow = kendo.getWindow(this.SUMMARIZATION_POPUP);
        this._sponsorChangeInviteWindow = kendo.getWindow("#sponsorChangeInvite");

        const submitButton = this.findElement(this.BUTTON_SUBMIT);
        if (!submitButton) {
            throw new Error("Submit button not found!");
        }
        this._submitBtn = kendo.createButton(submitButton);
        this._submitButtonManager = new SubmitButtonManager(submitButton);

        this._sponsorNumber = kendo.getTextBox(this.SPONSOR_NUMBER);
        this._emailInput = this.findElement(this.EMAIL_INPUT) as HTMLInputElement;
        this._telephoneInput = this.findElement(this.TELEPHONE_INPUT) as HTMLInputElement;
        this._sponsorFinalDetailsHidden = this.findElement(
            this.SPONSOR_FINAL_DETAILS_HIDDEN_ID
        ) as HTMLInputElement;
        this._sponsorName = this.findElement(this.SPONSOR_NAME) as HTMLInputElement;

        const sponsorRadioButtons = this.find(this.SEL_SPONSOR_RADIO_INPUT);
        let radio: kendo.ui.RadioButton | undefined;
        sponsorRadioButtons.forEach((radioElement) => {
            radio = kendo.getRadioButton(radioElement);
            if (radio) {
                this._sponsorRadioButtons.push(radio);
            }
        });
        this._sponsorIsMeEnumValue = Utils.getData(
            this.SPONSOR_RADIO_GROUP_ID,
            this.SPONSOR_CONSTANT_KEY
        );

        this._visibilityToggleCheckbox = kendo.getCheckBox(this.VISIBILITY_TOGGLE);

        this._documentComponentFactory.createAll(this.context);
        this._termsAndConditionsElement = this.findElement(
            this.TERMS_AND_CONDITIONS_SEL
        ) as HTMLLabelElement;

        const selApprovalsToggle = ".js-approvals-section-toggle";
        if (Utils.find(this._context, selApprovalsToggle).length > 0) {
            this._approvalToggle = this.createComponent(Div, selApprovalsToggle);
        }

        const selApprovalsToggleIcon = ".js-approvals-toggle-icon";
        if (Utils.find(this._context, selApprovalsToggleIcon).length > 0) {
            this._approvalToggleIcon = this.createComponent(Span, selApprovalsToggleIcon);
        }

        const selApprovalsSection = ".js-approvals-section-wrapper";
        if (Utils.find(this._context, selApprovalsSection).length > 0) {
            this._approvalSectionBlock = this.createComponent(Div, selApprovalsSection);
        }
    }

    /**
     * Initialization registration form
     */
    private _initForm(): void {
        const form = this.findElement(this.CONSULTANT_REGISTRATION_FORM);
        if (!form) {
            throw new Error("Form element not found!");
        }

        const config = {
            applyOverlay: true,
            callbackAlways: this._onSubmitSuccess,
            callbackBefore: this._onBeforeSubmit,
            callbackError: this._onSubmitError,
            disableInputs: true,
            isValidatorUsed: true,
            submitMode: FormSubmitMode.FetchApi,
            trimFields: true,
            validatorOptions: {
                inputRemoteValidate: this._onRemoteValidate,
                showErrorSummary: true,
                validate: this._onValidate,
            },
        };

        this._form = this.createComponent(Form, this.CONSULTANT_REGISTRATION_FORM, config);
        if (this._form.validator) {
            this._validator = this._form.validator;
        } else {
            this._validator = kendo.createValidator(form, {
                showErrorSummary: true,
                showFieldErrorsInSummary: true,
                showFieldErrorsInWidgets: true,
            });
        }

        this._initApprovalsSection();
        this._setDefaultBirthdateYear();
        this._isSelfRegistration = Utils.is(form, this.SELF_REGISTRATION_FORM);
        this._toggleVisibilityTarget(this._visibilityToggleCheckbox);
    }

    /**
     * Initialization foreign registration search form
     */
    private _initForeignRegistrationSearchForm(): void {
        this._searchFormConsultantNumber = kendo.getTextBox(this.SEL_SEARCH_FORM_CONSULTANT_NUMBER);
        if (!this._searchFormConsultantNumber) {
            return;
        }
        this._searchFormConsultantNumber.element.bindFocusOut(this._triggerConsultantSearch);

        const foreignRestrationSerchForm = Utils.closest(
            this._searchFormConsultantNumber.wrapper,
            this.FOREIGN_REGISTRATION_SEARCH_FORM
        );
        if (!foreignRestrationSerchForm) {
            throw new Error("Foreign registration consultant search form not found");
        }

        const sectionConsultantNumber = Utils.closest(
            foreignRestrationSerchForm,
            this.SECTION_CONSULTANT_NUMBER
        );
        if (!sectionConsultantNumber) {
            throw new Error("Foreign registration consultant search section not found");
        }

        const config = {
            applyOverlay: true,
            callbackOnSuccess: this._onForeignSearchSubmitSuccess,
            disableInputs: true,
            isValidatorUsed: true,
            submitMode: FormSubmitMode.FetchApi,
            trimFields: true,
        };

        this._foreignRegistrationSerchForm = this._componentFactory.create(
            sectionConsultantNumber,
            Form,
            this.FOREIGN_REGISTRATION_SEARCH_FORM,
            config
        );
        this._sectionConsultantNumber = sectionConsultantNumber as HTMLDivElement;
    }

    /**
     * Options
     */
    private _setOptions(): void {
        this._ajaxErrorTitle = this.getData(this.AJAX_ERROR_TITLE);
        this._ajaxErrorMessage = this.getData(this.AJAX_ERROR_MESSAGE);
        this._logFormElementsStateUrl = this.getData(this.LOG_FORM_ELEMENTS_STATE);
        this._showSummarizationPopupUrl = this.getData(this.SHOW_SUMMARIZATION_POPUP);
        this._isEnabledSummarization = this._form.getData(this.IS_ENABLED_SUMMARIZATION);

        if (this._isEnabledSummarization && !this._showSummarizationPopupUrl) {
            throw new Error("Summarization URL does not exist!");
        }
    }

    /**
     * Bind events
     */
    private _bindEvents(): void {
        this._form.bindChange(this._validateUniqueId);

        if (this._summarizationWindow) {
            this._summarizationWindow.bindActivate(this._onSummarizationPopupActivation);
            this._summarizationWindow.bindDeactivate(this._onSummarizationPopupClose);
            this._summarizationWindow.bindClose(this._onSummarizationPopupClose);
        }

        if (this._sponsorChangeInviteWindow) {
            this._sponsorChangeInviteWindow.bindDeactivate((e) => {
                e?.sender.destroy();
            });
            this._sponsorChangeInviteWindow.bindActivate(this._initSponsorChangeInviteWindow);
        }

        if (this._isForeignCheckBox) {
            this._isForeignCheckBox.bindChange(this._onIsForeignChange);
        }

        this._sponsorRadioButtons.map((radio) => radio.bindChange(this._onSponsorDetailChange));

        if (this._visibilityToggleCheckbox) {
            this._visibilityToggleCheckbox.bindChange(this._onVisibilityToggleChange);
        }
    }

    private _initSponsorChangeInviteWindow = (e: kendo.ui.WindowEvent) => {
        this._componentFactory.create(
            e.sender.element[0],
            SponsorChangeInviteContent,
            ".js-sponsor-change-invite-content"
        );
    };

    private _onSummarizationPopupActivation = () => {
        $(window).bindOrientationChange(() => {
            if (this._summarizationWindow) {
                this._summarizationWindow.center();
            }
        }, this.SUMMARIZATION_EVENT_NAMESPACE);
    };

    private _onSummarizationPopupClose = (): void => {
        this._form.toggleInputWidgets(true);
        this._submitBtn.element.removeAttr("disabled");
        Utils.setCss("html, body", "overflow", "visible");
        this._isSummarizationOpened = false;
        $(window).unbindResize(this.SUMMARIZATION_EVENT_NAMESPACE);
        $(window).unbindOrientationChange(this.SUMMARIZATION_EVENT_NAMESPACE);
    };

    private _bindSummarizationButtons = (): void => {
        const confirmBtn = kendo.getButton(this.SEL_SUMMARIZATION_CONFIRM);
        const closeBtn = kendo.getButton(this.SEL_SUMMARIZATION_CLOSE);
        if (confirmBtn) {
            confirmBtn.bindClick(this._confirmSummarizationPopup);
        }
        if (closeBtn) {
            closeBtn.bindClick(this._closeSummarizationPopup);
        }
    };

    private _openSummarizationPopup(content: string): void {
        if (!this._summarizationWindow) {
            return;
        }
        this._form.toggleInputWidgets(false);
        this._submitBtn.element.attr("disabled", "true");
        this._summarizationWindow.wrapper.addClass("summarization-popup");
        this._summarizationWindow.wrapper.css("touch-action", "initial");
        this._summarizationWindow.setOptions({
            resizable: false,
            scrollable: true,
            draggable: false,
        });
        this._summarizationWindow.content(content).open().center();
        this._bindSummarizationButtons();
        this._isSummarizationOpened = true;
    }

    private _populateTermsAndConditionsToSummarizationPopup(): void {
        if (this._termsAndConditionsElement && this._summarizationWindow) {
            const termsAndConditionsSummaryElement = Utils.find(
                this._summarizationWindow.wrapper,
                this.TERMS_AND_CONDITIONS_SUMMARY_SEL
            )[0] as HTMLDivElement;
            termsAndConditionsSummaryElement.innerHTML = this._termsAndConditionsElement.innerHTML;
        }
    }

    private _closeSummarizationPopup = (event: kendo.ui.ButtonClickEvent): void => {
        if (!this._summarizationWindow) {
            return;
        }
        this._summarizationWindow.close();
        event.preventDefault();
        event.event.stopPropagation();
    };

    private _confirmSummarizationPopup = (): void => {
        if (this._summarizationWindow) {
            this._summarizationWindow.close();
        }
        this.summarizationSubmit = false;
        this._form.submit();
    };

    // Unique ID validation
    @autobind
    private _validateUniqueId(event: JQuery.TriggeredEvent): void {
        if (
            !$(event.target).is(this.UNIQUE_ID_RELATED_FIELD_SEL) &&
            !$(event.target).is(this.UNIQUE_ID_SEL)
        ) {
            return;
        }
        if (this._uniqueId) {
            const uniqueIdValue = this._uniqueId.value();
            const uniqueIdData = this._uniqueId.element.data(
                this.UNIQUE_ID_TRIGGER_VALIDATION_BY_RELATED_FIELDS_DATA
            );

            if (uniqueIdValue && uniqueIdValue.length > 0 && uniqueIdData === "True") {
                this._disableSubmitButton();
                this._validator.validateInput(this._uniqueId.element);
            }
        }
    }

    // Consultant search
    @autobind
    private _triggerConsultantSearch(): void {
        if (!this._searchFormConsultantNumber || !this._foreignRegistrationSerchForm) {
            return;
        }
        const consultantNumberVal = this._searchFormConsultantNumber.element.val();

        if (consultantNumberVal) {
            this._foreignRegistrationSerchForm.submit();
        }
    }

    // Callback functions
    @autobind
    private _onBeforeSubmit(_form: HTMLFormElement): boolean | string {
        if (this._isEnabledSummarization && this.summarizationSubmit) {
            return this._showSummarizationPopupUrl;
        }
        // we have to use Normal submit mode for self-registration because of identity servers
        if (this._isSelfRegistration) {
            this._form.submitMode = FormSubmitMode.Normal;
        }
        return true;
    }

    @autobind
    private _onSubmitError(): void {
        this._logger.error("submitRegistrationForm call failed");
        if (this._ajaxErrorTitle && this._ajaxErrorMessage) {
            ori.displayError(this._ajaxErrorTitle, this._ajaxErrorMessage);
        }
        this.summarizationSubmit = true;
    }

    @autobind
    private _onSubmitSuccess(_form: HTMLFormElement, response: ISubmitResponse): void {
        if (response.result.Success) {
            if (
                this._isEnabledSummarization &&
                !this._isSummarizationOpened &&
                this.summarizationSubmit
            ) {
                this._openSummarizationPopup(response.result.View);
                this._populateTermsAndConditionsToSummarizationPopup();
                return;
            }
            const popup = kendo.getWindow(this.REGISTRATION_SUCCESS_DIALOG);
            if (popup) {
                if (response.result.View !== "") {
                    popup.content(response.result.View);
                }
                popup.center().open();
                const event = new CustomEvent(HandlePartialViewAnalytics);
                window.dispatchEvent(event);
            }
            this.summarizationSubmit = true;
            return;
        }
        const originalAction = this._form.getAttr("action") ?? "";
        this.setHtml(response.result.View ? response.result.View : response.result);
        this.init(false);
        this._form.setAttr("action", originalAction);
        this._binder.trigger("DOMRefresh", this.context);
    }

    @autobind
    private _onForeignSearchSubmitSuccess(_form: HTMLFormElement, response: ISubmitResponse): void {
        if (!this._sectionConsultantNumber) {
            return;
        }
        if (response.result.Success) {
            this.setHtml(response.result.View);
            this.init(false);
            this._binder.trigger("DOMRefresh", this.context);
            return;
        }
        Utils.setHtml(this._sectionConsultantNumber, response.result.View);
        this._initForeignRegistrationSearchForm();
        this._binder.trigger("DOMRefresh", this._sectionConsultantNumber);
    }

    // Input remote Validate
    @autobind
    private _onRemoteValidate(event: kendo.ui.ValidatorInputRemoteValidateEvent): void {
        if (event.inputName === this.UNIQUE_ID_INPUT_NAME) {
            this._enableSubmitButton();
        }
        if (
            !this._sponsorNumber ||
            !this._sponsorName ||
            !this._sponsorName.parentElement ||
            !Utils.isVisible(this._sponsorNumber.element)
        ) {
            return;
        }

        const isSponsorChanged: boolean =
            event.response && event.response.sponsorName !== undefined;

        if (!isSponsorChanged) {
            return;
        }

        const showSponsorName: boolean = event.valid && event.response.sponsorName !== "";

        Utils.setText(this._sponsorName, showSponsorName ? event.response.sponsorName : "");
        Utils.toggleClass(this._sponsorName.parentElement, this.CLASS_HIDDEN, !showSponsorName);
    }

    // on Validate
    @autobind
    private _onValidate(event: kendo.ui.ValidatorValidateEvent): void {
        if (!event.valid) {
            this._logFormErrors(event.sender);
        }
    }

    @autobind
    private _initApprovalsSection(): void {
        if (!this._approvalToggle || !this._approvalToggleIcon || !this._approvalSectionBlock) {
            return;
        }

        this._approvalToggle.bindClick(this._approvalToggleClick, this.key);
    }

    @autobind
    private _approvalToggleClick(event: JQuery.Event): void {
        event.preventDefault();

        if (!this._approvalToggle || !this._approvalToggleIcon || !this._approvalSectionBlock) {
            return;
        }

        if (Utils.isVisible(this._approvalSectionBlock.context)) {
            Utils.slideUp(this._approvalSectionBlock.context);
        } else {
            Utils.slideDown(this._approvalSectionBlock.context);
        }

        this._approvalToggleIcon
            .toggleClass("v-icon-arrow-up-thin")
            .toggleClass("v-icon-arrow-down-thin");
    }

    // Set default birth date
    private _setDefaultBirthdateYear(): void {
        const birthDateInput = this._form.findElement(this.BIRTHDATE_INPUT);
        if (!birthDateInput) {
            return;
        }
        const defaultYear = Utils.getAttr(birthDateInput, this.BIRTHDATE_DEFAULT_YEAR);

        if (defaultYear && $.isNumeric(defaultYear)) {
            const birthDateControl = kendo.getDropDownDatePicker(birthDateInput);
            if (birthDateControl) {
                if (birthDateControl.value()) {
                    return;
                }

                birthDateControl.year.value(defaultYear);
            }
        }
    }

    // Log form errors
    private _logFormErrors = (validator: kendo.ui.Validator): void => {
        if (!validator.element.length) {
            return;
        }

        if (!this._logFormElementsStateUrl) {
            this._logger.warning("Wrong configuration of URL for logging form elements state.");
            return;
        }

        const formElements = Array.from(this._form.context.elements);
        const data: IFormData[] = [];
        const errors = validator.errors();

        formElements.forEach((element) => {
            const elementTypeAttr = Utils.getAttr(element, "type");
            const isElementVisible = Utils.is(element, ":visible");

            if (elementTypeAttr === "submit" || !isElementVisible) {
                return;
            }
            const checkboxVal =
                elementTypeAttr === "checkbox"
                    ? Utils.is(element, ":checked")
                    : Utils.getValue(element);
            const elementValue = String(elementTypeAttr === "password" ? "" : checkboxVal ?? "");
            const elementName = Utils.getAttr(element, "name") ?? "";
            const error = errors.hasOwnProperty(elementName) ? errors[elementName] : "";

            const formElement: IFormData = {
                ElementName: elementName,
                ElementValue: elementValue,
                Errors: error,
            };

            data.push(formElement);
        });

        if (this._logFormElementsStateUrl) {
            const formData = new FormData();
            this._fetchService.appendValue(formData, "elements", data);
            this._fetchService.post(this._logFormElementsStateUrl, formData);
        }
    };

    // Sponsor radio buttons
    @autobind
    private _onSponsorDetailChange(event: kendo.ui.RadioButtonChangeEvent): void {
        if (!this._sponsorFinalDetailsHidden || !event.sender || !event.sender.element) {
            return;
        }

        const sponsorIsMeSelected = event.sender.element.val() === this._sponsorIsMeEnumValue;
        Utils.setValue(this._sponsorFinalDetailsHidden, event.sender.element.val() ?? "");

        if (!this._sponsorNumber || !this._sponsorName) {
            return;
        }

        const parentSponsorName = this._sponsorName.parentElement;
        if (parentSponsorName) {
            this._sponsorName.textContent = "";
            Utils.addClass(parentSponsorName, this.CLASS_HIDDEN);
        }

        const parentSponsorNumber = this._sponsorNumber.wrapper.parent();
        Utils.toggleClass(parentSponsorNumber, this.CLASS_HIDDEN, sponsorIsMeSelected);

        if (sponsorIsMeSelected) {
            this._sponsorNumber.value(
                Utils.getData(this._sponsorNumber.element, this.CONSULTANT_NUMBER)
            );
            this._validator.updateConditionalRequired(this._sponsorNumber.element, false);
        } else {
            this._sponsorNumber.value("");
            this._validator.updateConditionalRequired(this._sponsorNumber.element, true);
        }
    }

    // Guarantee checkbox change
    @autobind
    private _onVisibilityToggleChange(event: kendo.ui.CheckBoxChangeEvent): void {
        if (!event.sender || !event.sender.element) {
            return;
        }
        this._toggleVisibilityTarget(event.sender);
    }

    // Guarantee checkbox change
    @autobind
    private _toggleVisibilityTarget(checkBox: kendo.ui.CheckBox | undefined): void {
        if (!checkBox || !checkBox.element) {
            return;
        }
        const checked = checkBox.value();
        const selector = Utils.getData(checkBox.element, this.VISIBILITY_TOGGLE_TARGET);
        const targets = this.find(selector);
        targets.forEach((element) => {
            const target = element as HTMLDivElement;
            if (target) {
                const inverted = Utils.getData(target, this.INVERT_VISIBILITY_TOGGLE) as boolean;
                Utils.toggleClass(target, this.CLASS_HIDDEN, inverted ? checked : !checked);
            }
        });
    }

    private _disableSubmitButton(): void {
        if (this._disableSubmitTimer) {
            window.clearTimeout(this._disableSubmitTimer);
        }
        this._submitButtonManager.disableFor(this.DISABLE_SUBMIT_FOR);
        this._disableSubmitTimer = window.setTimeout(() => {
            this._enableSubmitButton();
        }, this.DISABLE_SUBMIT_TIMEOUT);
    }

    private _enableSubmitButton(): void {
        if (this._disableSubmitTimer) {
            window.clearTimeout(this._disableSubmitTimer);
        }
        this._submitButtonManager.enableFor(this.DISABLE_SUBMIT_FOR);
    }

    // Foreign registration change function
    private _onIsForeignChange = (event: kendo.ui.CheckBoxChangeEvent): void => {
        if (!this._uniqueId) {
            return;
        }
        if (!event.sender) {
            return;
        }
        const isChecked = event.sender.value();
        if (isChecked) {
            const foreignUniqueId = Utils.getAttr(
                this._uniqueId.element,
                this.DATA_FOREIGN_UNIQUE_ID_ATTR
            );
            this._uniqueId.value(foreignUniqueId ?? "");
            this._uniqueId.readonly(true);
        } else {
            this._uniqueId.value("");
            this._uniqueId.readonly(false);
        }
    };

    // close popup
    public onClosePopup(): void {
        window.location.reload();
    }

    public onNotificationTypeChange = (): void => {
        if (!this._validator) {
            // in this case, the form has just been returned from backend POST request
            // and has not yet been fully initialized. As such, it cannot contain user-input errors.
            return;
        }
        if (this._emailInput) {
            this._validator.validateInput(this._emailInput);
        }
        if (this._telephoneInput) {
            this._validator.validateInput(this._telephoneInput);
        }
    };

    public onConsultanTypeChange = (event: kendo.ui.ValidatorEvent): void => {
        const selectedValue = parseInt(String(event.sender.element.val() ?? ""), 10);
        const consultantTypeSpecificModules = this.find(
            "[data-" + this.DATA_CONSULTANT_TYPE_HIDE_FOR + "]"
        );

        consultantTypeSpecificModules.forEach((element: Element, _index: number) => {
            const selectedHideFor = Utils.getData(element, this.DATA_CONSULTANT_TYPE_HIDE_FOR);
            const selectedData = Array.isArray(selectedHideFor);
            const hide = selectedData
                ? selectedHideFor.indexOf(selectedValue) >= 0
                : selectedHideFor === selectedValue;

            Utils.toggleClass(element, this.CLASS_HIDDEN, hide);
        });
    };

    public onDropDownTypeOpen = (element: Element): void => {
        element.setAttribute(this.DATA_COLLAPSE, "true");
    };

    public onDropDownTypeClose = (element: Element): void => {
        element.removeAttribute(this.DATA_COLLAPSE);
    };
}
