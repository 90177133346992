import { Inject } from "typescript-ioc";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { Form } from "Ui/Scripts/Form";
import { autobind } from "core-decorators";
import { FormSubmitMode } from "Ui/Scripts/FormSubmitMode";
import { ISubmitResponse } from "Ui/Scripts/ISubmitResponse";
import { Div } from "Ui/Scripts/Div";

export class SponsorChangeInviteContent extends UiComponent {
    private _erroMessage: Div;

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory
    ) {
        super(componentFactory, binder, loggerFactory);
    }

    public init(): void {
        this._erroMessage = this.createComponent(Div, ".js-sponsor-change-invite-error");
        this.createComponent(Form, "form.js-sponsor-change-invite", {
            applyOverlay: true,
            callbackOnSuccess: this._onSubmitSuccess,
            submitMode: FormSubmitMode.FetchApi,
        });
    }

    @autobind
    private _onSubmitSuccess(_form: HTMLFormElement, response: ISubmitResponse): void {
        if (response.result.Success) {
            window.location.reload();
        } else {
            this._erroMessage.setText(response.result.ErrorMessage);
            this._erroMessage.toggle(true);
        }
    }
}
